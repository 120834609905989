// This file has to be JS since it is imported by Gatsby's lifecycle APIs

import * as React from 'react'
import * as store from 'store'
import * as shopify from 'shopify-buy'

export const domain = 'checkout.lieuditwinery.com'
export const storefrontAccessToken = '48f378f4f985452505db04b6f7ce1791'

export const client = shopify.buildClient({
  domain,
  storefrontAccessToken,
})

export const CartContext = React.createContext(undefined)

export const CartProvider = props => {
  const [cart, setCart] = React.useState()
  const [isOpen, setIsOpen] = React.useState(false)

  const value = React.useMemo(
    () => ({
      cart,
      setCart,
      isOpen,
      toggleOpen: () => setIsOpen(c => !c),
    }),
    [cart, isOpen, setIsOpen]
  )

  const fetchCart = async () => {
    const previousCheckoutId = store.get('checkoutId')

    if (previousCheckoutId) {
      try {
        let cart = await client.checkout.fetch(previousCheckoutId)
        if (cart) {
          if (cart.completedAt) {
            // checkout has been completed, create a new one
            cart = await client.checkout.create()
            store.set('checkoutId', cart.id)
          }

          setCart(cart)

          return cart
        }
      } catch (e) {
        // It's most likely that something went wrong fetching the old cart,
        // send this error to Sentry and continue on to create a new cart
        // Uncomment this once sentry is installed/configured
        Sentry.captureException(e)
      }
    }

    const newCart = await client.checkout.create()

    store.set('checkoutId', newCart.id)

    setCart(newCart)
  }

  React.useEffect(() => {
    fetchCart()
  }, [])

  return <CartContext.Provider value={value} {...props} />
}

export default CartProvider
