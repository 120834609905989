/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react')
const { CartProvider } = require('./src/CartProvider')

exports.wrapRootElement = ({ element }) => (
  <CartProvider>{element}</CartProvider>
)
